$blue: #00acc8;
$purple: #92278f;
$green: #95D600;
$orange: #F7921E;
$pink: #F64B7F;

@import url('https://fonts.googleapis.com/css?family=Quicksand|Satisfy');

/* header and navbar */
header{
  border-top:5px solid $blue;
  background-color: $blue;
  border-bottom: 10px solid $green;
}

.navbar{
  margin-bottom:0;
  min-height:80px;
}
.navbar-brand {
  padding: 0 5px;
  img {
    padding-top:5px;
    max-width:240px;
  }
}

.navbar-nav>li>a {
  padding-top:30px;
  color: #FFF;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
  color: $purple;
}
.navbar-toggle {
  background-color:$purple;
  margin-right:0;

  .icon-bar {
    background-color:#FFF;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px;
  font-family:'Quicksand', Arial, sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$purple;
}
a:hover, a:active{
  color:$green;
  text-decoration:none;
}
h1, h2, h3, h4, h5, h6{
  color : $purple;
  font-family:'Quicksand', Arial, sans-serif;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $purple;
  border:1px solid $purple;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.label{
  font-size:100%;
  color:$purple;
  padding:0;
}
form{
  p.control{
    margin-bottom:0 !important;
  }
}
main {
  padding:30px 0 30px;
}
/*sweet tips */
.tip-excerpt{
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom:1px solid #ededed;
  img{
    float:left;
    margin:0 20px 70px 0;
  }
  p{
    height:70px;
    overflow: hidden;
  }
}
.tip{
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom:1px solid #ededed;
  img{
    float:left;
    margin:0 20px 70px 0;
  }
  p{
    height:auto;
  }
}
/* blog posts */
.post-excerpt {
  clear: both;
  margin-bottom: 20px;
  padding: 0 0 20px 160px;
  border-bottom: 1px solid #ededed;
  background-image: url(/images/pink-candy.png);
  background-repeat: no-repeat;
  background-size: 140px;
  background-position-y: 10px;
  img {
    float: left;
    margin: 0 20px 70px 0;
  }
  p {
    height: 70px;
    overflow: hidden;
  }
}
.post-excerpt:nth-child(2) {
  background-image: url(/images/purple-candy.png);
}
.post-excerpt:nth-child(3) {
  background-image: url(/images/orange-candy.png);
}
.post-excerpt:nth-child(4) {
  background-image: url(/images/green-candy.png);
}
.post {
  padding: 0 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
  p{
    margin-bottom:10px;
    padding:10px 0;
  }
  h1, h2, h3, h4, h5, h6{
    padding-left:10px;
  }
}
.meta {
  margin-top:0;
  font-size:13px;
  display:block;
  padding:10px;
  background-color:#f9f9f9;
  i {
    color:$pink;
  }
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 24px;
  line-height: 37px;
  height:auto;
  margin-bottom: 0;
  padding: 10px 0;
  color: $blue;
  a {
    color: $blue;
  }
}
p strong {
  color: $purple;
  font-size: 15px;
}
.pagination {
  display: block;
  min-height: 50px;
  margin-top:0;
  clear: both;
  a {
    text-transform: uppercase;
    color: $purple;
    border-bottom: 1px solid $purple;
    letter-spacing: .1em;
  }
  a.right {
    display: inline-block;
    float: right;
  }
  a.left {
    display: inline-block;
    float: left;
  }
  a:hover {
    text-decoration: none;
    font-weight: bold;
  }
}


/* sidebar */
aside {
  h3.widget-title {
    font-size: 20px;
    line-height: 23px;
    padding:10px;
    font-weight: normal;
    margin-top: 0;
    color: white;
    background-color:$pink;
    letter-spacing: 2px;
    border-bottom: 1px solid #ededed;
  }
  .textwidget{
    margin:-11px 0 20px;
    h4{
      display:none;
    }
    form{
      padding:30px 20px 10px;
      border:2px solid #ededed;
      border-top:0;
      input[type="submit"]{
        margin-bottom:20px;
        background-color:$purple;
        border-color: $purple;
      }
    }
  }
  ul{
    margin-bottom:30px;
    li{
      flex-basis:100% !important;
    }
    i.fa{
      width: 30px;
      text-align: center;
    }
    a{
      color:$blue;
    }
  }
}
/* footer */
.before-footer{
  background-color:$orange;
  color:#FFF;
  padding:30px 0 20px;
  .right{
    text-align:right;
  }
  .center{
    text-align:center;
    margin:auto;
    .tip-excerpt {
      border-bottom:0;
      margin-bottom:0;
      padding-bottom: 0;
      img {
        width: 80px;
        float:none;
        margin-bottom:10px;
      }
      p.title {
        display: none;
      }
    }
  }
  h3{
    color:#FFF;
    font-size:18px;
  }
  a{
    color:#FFF;
    display:block;
    padding:5px 0;
  }
  a:hover{
    color:lightyellow;
  }
}
footer{
  background-color:$pink;
  padding: 30px 0;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  .container{
    margin-left: auto;
    max-width: 100%;
  }
  a {
    display: none;
  }
  .copyright {
    padding-right: 10px;
    font-size:12px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  a {
    display:block;
    background-color: $purple;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $green !important;
  padding               : 10px 0 20px;
  text-align            : left;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 30px;
    color: #FFF;
    font-weight: 700;
    max-width:1140px;
    margin:0 auto;
    padding:0 15px;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$purple;
  padding: 20px 0 30px;
  margin-top:-10px;
  h3{
    color:#FFF;
    padding-top: 0;
    font-size: 1.7em;
    font-weight: 700;
    text-transform: uppercase;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
  }
  input[type="submit"]{
    background-color:$green;
    border:1px solid $green;
    color:#FFF;
    display:inline-block;
    height:45px;
    border-radius: 23px;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}
/* section home 1 */
#home1 div.icon{
  text-align: center;
  margin:auto;
  img{
    padding:10px 0 20px;
  }
}
#home1{
  margin:30px auto 40px;
  h3{
    font-family:'Satisfy', Arial, sans-serif;
  }
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding:10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color:$blue;
  letter-spacing: 2px;
}
aside ul li {
  flex-basis: 100%;
  padding-bottom:5px;
  a i{
    color: $pink !important;
  }
  a:hover{
    color:$purple;
  }
}

/*hero section*/
.hero{
  background-image:url('/images/candy-header.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  padding:8% 0 10%;
  .hero_message{
    padding:5%;
    background-color:rgba(254,254,254,.7);
    text-align: center;
    h1{
      color:$purple;
      font-family: 'Satisfy', Arial, sans-serif;
    }
    p{
      color:#333;
      font-size:17px;
      line-height:35px;
      letter-spacing: .03rem;
    }
    a{
      background-color:$purple;
      color:#FFF;
      border-radius: 20px;
    }
  }
}

/* cookie alert */
.alert{
  margin-bottom:0;
}
.alert-success{
  background-color:white;
  border:none;
  color:#333;
  strong{
    color:#333
  }
  a{
    color:#333;
  }
}

