@import url("https://fonts.googleapis.com/css?family=Quicksand|Satisfy");
/* header and navbar */
header {
  border-top: 5px solid #00acc8;
  background-color: #00acc8;
  border-bottom: 10px solid #95D600;
}

.navbar {
  margin-bottom: 0;
  min-height: 80px;
}

.navbar-brand {
  padding: 0 5px;
}

.navbar-brand img {
  padding-top: 5px;
  max-width: 240px;
}

.navbar-nav > li > a {
  padding-top: 30px;
  color: #FFF;
  text-transform: uppercase !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
  color: #92278f;
}

.navbar-toggle {
  background-color: #92278f;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: #FFF;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px;
  font-family: 'Quicksand', Arial, sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #92278f;
}

a:hover, a:active {
  color: #95D600;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #92278f;
  font-family: 'Quicksand', Arial, sans-serif;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #92278f;
  border: 1px solid #92278f;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.label {
  font-size: 100%;
  color: #92278f;
  padding: 0;
}

form p.control {
  margin-bottom: 0 !important;
}

main {
  padding: 30px 0 30px;
}

/*sweet tips */
.tip-excerpt {
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
}

.tip-excerpt img {
  float: left;
  margin: 0 20px 70px 0;
}

.tip-excerpt p {
  height: 70px;
  overflow: hidden;
}

.tip {
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
}

.tip img {
  float: left;
  margin: 0 20px 70px 0;
}

.tip p {
  height: auto;
}

/* blog posts */
.post-excerpt {
  clear: both;
  margin-bottom: 20px;
  padding: 0 0 20px 160px;
  border-bottom: 1px solid #ededed;
  background-image: url(/images/pink-candy.png);
  background-repeat: no-repeat;
  background-size: 140px;
  background-position-y: 10px;
}

.post-excerpt img {
  float: left;
  margin: 0 20px 70px 0;
}

.post-excerpt p {
  height: 70px;
  overflow: hidden;
}

.post-excerpt:nth-child(2) {
  background-image: url(/images/purple-candy.png);
}

.post-excerpt:nth-child(3) {
  background-image: url(/images/orange-candy.png);
}

.post-excerpt:nth-child(4) {
  background-image: url(/images/green-candy.png);
}

.post {
  padding: 0 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
}

.post p {
  margin-bottom: 10px;
  padding: 10px 0;
}

.post h1, .post h2, .post h3, .post h4, .post h5, .post h6 {
  padding-left: 10px;
}

.meta {
  margin-top: 0;
  font-size: 13px;
  display: block;
  padding: 10px;
  background-color: #f9f9f9;
}

.meta i {
  color: #F64B7F;
}

.author, .date {
  padding: 0 10px 0 5px;
}

p.title {
  font-size: 24px;
  line-height: 37px;
  height: auto;
  margin-bottom: 0;
  padding: 10px 0;
  color: #00acc8;
}

p.title a {
  color: #00acc8;
}

p strong {
  color: #92278f;
  font-size: 15px;
}

.pagination {
  display: block;
  min-height: 50px;
  margin-top: 0;
  clear: both;
}

.pagination a {
  text-transform: uppercase;
  color: #92278f;
  border-bottom: 1px solid #92278f;
  letter-spacing: .1em;
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* sidebar */
aside h3.widget-title {
  font-size: 20px;
  line-height: 23px;
  padding: 10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #F64B7F;
  letter-spacing: 2px;
  border-bottom: 1px solid #ededed;
}

aside .textwidget {
  margin: -11px 0 20px;
}

aside .textwidget h4 {
  display: none;
}

aside .textwidget form {
  padding: 30px 20px 10px;
  border: 2px solid #ededed;
  border-top: 0;
}

aside .textwidget form input[type="submit"] {
  margin-bottom: 20px;
  background-color: #92278f;
  border-color: #92278f;
}

aside ul {
  margin-bottom: 30px;
}

aside ul li {
  flex-basis: 100% !important;
}

aside ul i.fa {
  width: 30px;
  text-align: center;
}

aside ul a {
  color: #00acc8;
}

/* footer */
.before-footer {
  background-color: #F7921E;
  color: #FFF;
  padding: 30px 0 20px;
}

.before-footer .right {
  text-align: right;
}

.before-footer .center {
  text-align: center;
  margin: auto;
}

.before-footer .center .tip-excerpt {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.before-footer .center .tip-excerpt img {
  width: 80px;
  float: none;
  margin-bottom: 10px;
}

.before-footer .center .tip-excerpt p.title {
  display: none;
}

.before-footer h3 {
  color: #FFF;
  font-size: 18px;
}

.before-footer a {
  color: #FFF;
  display: block;
  padding: 5px 0;
}

.before-footer a:hover {
  color: lightyellow;
}

footer {
  background-color: #F64B7F;
  padding: 30px 0;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

footer .container {
  margin-left: auto;
  max-width: 100%;
}

footer a {
  display: none;
}

footer .copyright {
  padding-right: 10px;
  font-size: 12px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #92278f;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #95D600 !important;
  padding: 10px 0 20px;
  text-align: left;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 30px;
  color: #FFF;
  font-weight: 700;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #92278f;
  padding: 20px 0 30px;
  margin-top: -10px;
}

section#home-search h3 {
  color: #FFF;
  padding-top: 0;
  font-size: 1.7em;
  font-weight: 700;
  text-transform: uppercase;
}

section#home-search form {
  max-width: 75%;
  text-align: center;
  margin: auto;
  padding: 0;
}

section#home-search input[type="text"] {
  width: 37%;
}

section#home-search input[type="submit"] {
  background-color: #95D600;
  border: 1px solid #95D600;
  color: #FFF;
  display: inline-block;
  height: 45px;
  border-radius: 23px;
}

@media screen and (max-width: 782px) {
  section#home-search form {
    max-width: 90%;
  }
  section#home-search form input[type="text"] {
    width: 100%;
  }
}

/* section home 1 */
#home1 div.icon {
  text-align: center;
  margin: auto;
}

#home1 div.icon img {
  padding: 10px 0 20px;
}

#home1 {
  margin: 30px auto 40px;
}

#home1 h3 {
  font-family: 'Satisfy', Arial, sans-serif;
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding: 10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #00acc8;
  letter-spacing: 2px;
}

aside ul li {
  flex-basis: 100%;
  padding-bottom: 5px;
}

aside ul li a i {
  color: #F64B7F !important;
}

aside ul li a:hover {
  color: #92278f;
}

/*hero section*/
.hero {
  background-image: url("/images/candy-header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 8% 0 10%;
}

.hero .hero_message {
  padding: 5%;
  background-color: rgba(254, 254, 254, 0.7);
  text-align: center;
}

.hero .hero_message h1 {
  color: #92278f;
  font-family: 'Satisfy', Arial, sans-serif;
}

.hero .hero_message p {
  color: #333;
  font-size: 17px;
  line-height: 35px;
  letter-spacing: .03rem;
}

.hero .hero_message a {
  background-color: #92278f;
  color: #FFF;
  border-radius: 20px;
}

/* cookie alert */
.alert {
  margin-bottom: 0;
}

.alert-success {
  background-color: white;
  border: none;
  color: #333;
}

.alert-success strong {
  color: #333;
}

.alert-success a {
  color: #333;
}
